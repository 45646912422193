import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`ACTIONS`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-actions/"
              }}>{`Outbound Campaigns Actions`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Actions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique Actions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
      </tbody>
    </table>
    <h2>{`ALL`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/canned-messages/"
                }}>{`Canned Messages`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/chatbot-stats/"
                }}>{`Chatbot Stats`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/chatbot/"
                }}>{`Chatbot`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/contact-center-activity/"
                }}>{`Contact Center Activity`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/contacts-sales/"
                }}>{`Contacts Sales`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/contacts/"
                }}>{`Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/content/"
                }}>{`Content`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/conversions/"
                }}>{`Conversions`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/ecommerce/"
                }}>{`ECommerce`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/events/"
                }}>{`Events`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/ivr-nodes/"
                }}>{`IVR Nodes`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/ivr/"
                }}>{`IVR`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/leads/"
                }}>{`Leads`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/onsite-campaigns/"
                }}>{`Onsite Campaigns`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-email/"
                }}>{`Outbound Campaigns Email`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-push-actions/"
                }}>{`Outbound Campaigns Push Actions`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-sms/"
                }}>{`Outbound Campaigns SMS`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-voice/"
                }}>{`Outbound Campaigns Voice`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/segments-actions/"
                }}>{`Segments Actions`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/visitors/"
                }}>{`Visitors`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/visits/"
                }}>{`Visits`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/voice-stats/"
                }}>{`Voice Stats`}</a></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Categories`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content Feedback`}</p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metrics`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Actions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Added`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attempts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`DAvg`}{` `}{`Daily average`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Available`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Available operators`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bounces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bundles`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Busy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks canceled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checkout items`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checkout value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checkouts influenced by contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checkouts influenced by impressions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Call requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Chat requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`DAvg`}{` `}{`Daily average`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`DAvg`}{` `}{`Daily average`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts - Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts - Push Notification`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts - SMS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts - Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts with cobrowse`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts with sales`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversion value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Effective Busy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Effective Busy Time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Events`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Executions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`∑`}{` `}{`Sum`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fallback (Maximum executions)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`∑`}{` `}{`Sum`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fallback (Time limit)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`∑`}{` `}{`Sum`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Forwardings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR duration (s)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`Average`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (baseline)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with A/B variants)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with profiles)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with segments)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Instances`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Logged In Time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Node duration (ms)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`∑`}{` `}{`Sum`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Node interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Node session expirations`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`Average`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nr. of shopping cart items`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nr. of shopping carts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nr. of shopping carts with checkout`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ongoing Calls`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Page requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pause`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pending Callbacks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Price of RGU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`∑`}{` `}{`Sum`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RGUs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`∑`}{` `}{`Sum`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Refused`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Removed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sales`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Schedule`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Scheduled calls`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Shopping carts value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Available`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Busy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Pause`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`Average`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique sessions (with prints)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors (accepted)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors (with influenced conversions)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors (with prints)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors with impressions)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Usage of DTMF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`∑`}{` `}{`Sum`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Usage of voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`∑`}{` `}{`Sum`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`BROADCAST`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/onsite-campaigns/"
              }}>{`Onsite Campaigns`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Clicks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Entries`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`BROWSER VIEWS`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-actions/"
              }}>{`Outbound Campaigns Actions`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser Views`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique Browser Views`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
      </tbody>
    </table>
    <h2>{`CHAT`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/contacts/"
                }}>{`Contacts`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Live Chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`∑`}{` `}{`Sum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`∑`}{` `}{`Sum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfer Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers with Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers without Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`CLICKS`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-actions/"
              }}>{`Outbound Campaigns Actions`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Clicks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique Clicks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
      </tbody>
    </table>
    <h2>{`CONTACT STATUS`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-email/"
                }}>{`Outbound Campaigns Email`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-push-actions/"
                }}>{`Outbound Campaigns Push Actions`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-sms/"
                }}>{`Outbound Campaigns SMS`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-voice/"
                }}>{`Outbound Campaigns Voice`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action executed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Alert (soft bounce)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Awaiting confirmation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Clicked`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacting`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delivered`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Failed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Finished with success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Finished without success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ignored (blacklist)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ignored due to capping`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`In contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Read`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rescheduled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Returned (hard bounce)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent with success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent without success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`To be sent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`To contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transferred to skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsubscribed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`CONTENT EVENT`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/content/"
              }}>{`Content`}</a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Categories`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content Feedback`}</p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metrics`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Detail Type (experience)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Detail Type (segmentation)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique contents`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique elements`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
      </tbody>
    </table>
    <h2>{`FORM`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/contacts/"
              }}>{`Contacts`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`INBOUND`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/contact-center-activity/"
              }}>{`Contact Center Activity`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Available - Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Busy - Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Available - Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Busy - Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total Time Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`OTHERS`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-sms/"
              }}>{`Outbound Campaigns SMS`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Error message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`OUTBOUND`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/contact-center-activity/"
              }}>{`Contact Center Activity`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Available - Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Busy - Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Available - Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Busy - Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total Time Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`STATE`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/leads/"
              }}>{`Leads`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads with sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads without sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Open Leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`VIDEO`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/contacts/"
              }}>{`Contacts`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (VideoCall)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (VideoCall)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VideoCall`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`VIEWS`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/outbound-campaigns-actions/"
              }}>{`Outbound Campaigns Actions`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique Views`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Views`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`#`}{` `}{`Count`}</p></td>
        </tr>
      </tbody>
    </table>
    <h2>{`VOICE`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-reference/contacts/"
                }}>{`Contacts`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call-center did not answer (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Human validation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identified contacts (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Client (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Contact Center (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success classified (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`∑`}{` `}{`Sum`}</li><li>{`%`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`95th percentile`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Silence (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Voicemail (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`#`}{` `}{`Count`}</li><li>{`%`}{` `}{`Percentage`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`VOICE STATS_MK_TNUM`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-reference/voice-stats/"
              }}>{`Voice Stats`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T9+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T9+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`µ`}{` `}{`Average`}</li><li>{`m`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`Maximum`}</li></ul></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      